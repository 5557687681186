let myTimeout;

recreateTimeout();

function recreateTimeout() {
  if(myTimeout !== undefined) {
    clearTimeout(myTimeout)
  }
  myTimeout = setTimeout(myTime, 1000);
}

function myTime() {
  const date = new Date();
  const time = document.getElementById("myTime");
  time.innerText = date.toString();
  recreateTimeout();
}
